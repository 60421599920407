import React, { useState } from 'react';
import './App.css';
import Home from './Home';

function App() {
  return (
    <div>
      <Home />
    </div>
  )
}

export default App;