import React, { useState } from 'react';
//import './App.css';
import './Home.css';
import axios from 'axios';

function Home() {
  //const [prompt, setPrompt] = useState('');
  const [answer, setAnswer] = useState('');
  const url = process.env.production.REACT_APP_ASK_ASSISTANT_API;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(url)
    const userInput = document.getElementById("prompt").value;
    console.log(userInput);
    const userInputEvent = {"prompt": userInput};
    console.log(userInputEvent);
    document.getElementById("prompt").value = ""; //resets prompt box
    document.getElementById("chatBoxBody").innerHTML += '<div class="message"><p>'+userInput+'</p></div>';
    document.getElementById("chatBoxBody").innerHTML += '<div id="loading" class="response loading">Loading...</div>'; //maybe change to image
    scrollToBottom();

    console.log("submit pressed");
    axios.post(url, userInputEvent).then((response) => {
        console.log(response);
        setAnswer(response.data.assistant_response);
      document.getElementById("loading").remove();
      document.getElementById("chatBoxBody").innerHTML += '<div class="response"><p>'+response.data.assistant_response+'</p></div>';
      scrollToBottom();
    })
    document.getElementById("prompt").value = ""; //resets prompt box
  }

  function scrollToBottom() {
    document.getElementById("chatBoxBody").scrollTop = document.getElementById("chatBoxBody").scrollHeight;
  }

  return (
    <div class="App">
      <div>
      <form onSubmit={handleSubmit}>
      <div class="chat-box">
            <div class="chat-box-header">Purdue Assistant</div>
            <div class="chat-box-body" id="chatBoxBody">
              <div>{answer}</div>
            </div>
            <div class="chat-box-footer">
                <input name="prompt" id = "prompt" type="text" placeholder="Type a message..."
                  
                />
                <button type="submit">Send</button>
            </div>
        </div>
        </form>
      </div>
    </div>
  );
};

export default Home;